import React from "react"
import styled from "@emotion/styled";
import { FaTwitter, FaInstagram, FaFacebookF} from "react-icons/fa"
import Container from "./container";

const Styles = styled.footer`
  background: #464646;
  color: white;

  a {
    color: white;
    transition: all 0.1s ease;

    &:hover {
      color: inherit;
      opacity: 0.5;
    }
  }


  .my-footer {
    color: white;
    background: #0a0a0acc;
    font-weight: 100;
    font-size: 12px;

    .my-footer-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
      text-align: center;
      grid-gap: 16px;

      @media screen and (min-width: 400px) {
        text-align: left;
        grid-template-columns: repeat(auto-fill, minmax( calc(50% - 16px), 1fr));
      }
    }
  }

  .icons {
    display: grid;
    width: 180px;
    font-size: 16px;
    text-align: center;
    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr)); 
    margin: 0 auto;


    @media screen and (max-width: 400px) {
      text-align: center;
    }

    @media screen and (min-width: 400px) {
      margin: 0;
      float: right;

    }
  }

`
export default class Footer extends React.Component {
  render () {
    return (
      <Styles>
            <div className="my-footer">
              <Container>
                <div className="my-footer-grid">
                  <div>© {new Date().getFullYear()}, Last Card</div>

               

                  <div className="email">
                  <div className="icons">
                    <div>
                      <a href="https://twitter.com/getLastCard" target="_blank" ref="noopener noreferrer">
                    <FaTwitter /> 
                    </a>
                    </div>

                    <div>
                    <a href="https://www.instagram.com/getthelastcard/" target="_blank" ref="noopener noreferrer">
                    <FaInstagram /> 
                    </a>
                    </div>

                    <div>
                    <a href="#" target="_blank" ref="noopener noreferrer">
                    <FaFacebookF/>
                    </a>
                    </div>
                  
                  </div>
                  </div>
                </div>
              </Container>
            </div>
      </Styles>
    )
  }
}

