import { Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import styled from "@emotion/styled"
import Container from "./container"
import VerticalAlign from "../components/verticalAlign"
import HamburgerMenu from "react-hamburger-menu"

const Styles = styled.div`
  position: fixed;
  background: #16161600;
  width: 100%;
  z-index: 1000;
  height: 84px;

  transition: all 0.2s ease;

  &.active {
    background: #161616dd;
  }

  .my-logo {
    width: 48px;
  }

  .links {
    display: inline-block;
    display: none;
    float: right;
    height: 100%;
    text-align: right;
    padding-top: 10px;

    a {
      margin-left: 34px;
      color: #fff;
      transition: all 0.1s ease;
  
      &:hover {
        color: #ffffff77;
      }
    }

    
  }


`
export default class Header extends React.Component {
  constructor(props) {
    super()
    this.state = {}
    this.state.path = props.path
    this.state.open = false
  }

  setActive ( e ) {
    if (e.currentTarget.scrollY > 80 ) {
      this.setState({
        active: "active"
      })
    } else {
      this.setState({
        active: ""
      })
    }
  }

  componentDidMount() {

    if (typeof window !== undefined ) {
      window.onscroll = this.setActive.bind(this)
    }
  }

  toggleMenu() {
    document.querySelectorAll(".links")[0].classList.toggle("active")

    this.setState({
      open: !this.state.open,
    })
  }

  render() {
    return (
      <Styles className={ this.state.active }>
        <Container>
          
          
          <div className="links">
            <Link to="/log-in">Log In</Link>
            <Link to="/sign-up">Sign Up</Link>
          </div>
          

          
          <Link to="/">
              <Logo />
          </Link>
          

          
        </Container>
      </Styles>
    )
  }
}


const Logo = () => {
  const data = useStaticQuery(graphql`
    {

      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return(
    <Img className="my-logo" fluid={ data.logo.childImageSharp.fluid } alt="Last Card Logo" />
  )
}